import { createWebHashHistory, createRouter } from "vue-router";
import store from "./store/index.js";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  type: "warning",
  position: "top-right",
});

const rejectAuthUser = (to, from, next) => {
  if (store.state.isLogin === true) {
    toaster.show(`현재 로그인이 되어 있습니다.`);
    next("/Dashboard");
  } else {
    next();
  }
};

const onlyAuthUser = (to, from, next) => {
  if (store.state.isLogin === false) {
    toaster.show(`로그인을 다시 해주시기 바랍니다.`);
    next("/Login");
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    alias: "/login",
    name: "login",
    beforeEnter: rejectAuthUser,
    component: () => import("./components/Login"),
  },
  {
    path: "/dashboard",
    alias: "/dashboard",
    name: "dashboard",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Dashboard"),
  },
  {
    path: "/notice",
    alias: "/notice",
    name: "notice",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Notice"),
  },
  {
    path: "/study",
    alias: "/study",
    name: "study",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Studydata"),
  },
  {
    path: "/reports",
    alias: "/reports",
    name: "reports",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ClassReport"),
  },
  {
    path: "/weeklyreport",
    alias: "/weeklyreport",
    name: "weeklyreport",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/WeeklyReport"),
  },
  {
    path: "/studentlist",
    alias: "/studentlist",
    name: "studentlist",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/StudentList"),
  },
  {
    path: "/attendance",
    alias: "/attendance",
    name: "attendance",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendance"),
  },
  {
    path: "/memberList",
    alias: "/memberList",
    name: "memberList",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/MemberList"),
  },
  {
    path: "/attendtotal",
    alias: "/attendtotal",
    name: "attendtotal",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendtotal"),
  },
  {
    path: "/attendallcount",
    alias: "/attendallcount",
    name: "attendallcount",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendallcount"),
  },
  {
    path: "/membergoout",
    alias: "/membergoout",
    name: "membergoout",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/MemberGoout"),
  },
  {
    path: "/personreport",
    alias: "/personreport",
    name: "personreport",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/PersonReport"),
  },
  {
    path: "/newsletter",
    alias: "/newsletter",
    name: "newsletter",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Newsletter"),
  },
  {
    path: "/pray",
    alias: "/pray",
    name: "pray",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Pray"),
  },
  {
    path: "/studentassign",
    alias: "/studentassign",
    name: "studentassign",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/StudentAssign"),
  },
  {
    path: "/slidemenu",
    alias: "/slidemenu",
    name: "slidemenu",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/SlideMenu"),
  },
  {
    path: "/leaderassign",
    alias: "/leaderassign",
    name: "leaderassign",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/LeaderAssign"),
  },
  
  {
    path: "/memberassign",
    alias: "/memberassign",
    name: "memberassign",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/MemberAssign"),
  },
  
  {
    path: "/staffassign",
    alias: "/staffassign",
    name: "staffassign",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/StaffAssign"),
  },
  {
    path: "/staffreports",
    alias: "/staffreports",
    name: "staffreports",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/StaffReports"),
  },
  {
    path: "/allnotice",
    alias: "/allnotice",
    name: "allnotice",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Allnotice"),
  },
  {
    path: "/attendfamily",
    alias: "/attendfamily",
    name: "attendfamily",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Attendfamily"),
  },

  

  /*********************** */
  {
    path: "/trgtRegister",
    alias: "/trgtRegister",
    name: "trgtRegister",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/TrgtRegister"),
  },
  {
    path: "/manageMgmt",
    alias: "/manageMgmt",
    name: "manageMgmt",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ManageMgmt"),
  },
  {
    path: "/stateview",
    alias: "/stateview",
    name: "stateview",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ManageAllView"),
  },
  {
    path: "/realmgmt",
    alias: "/realmgmt",
    name: "realmgmt",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ManageRealtime"),
  },
  {
    path: "/dummy",
    alias: "/dummy",
    name: "dummy",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/Dummy"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./components/Logout"),
  },
  {
    path: "/chart",
    name: "chart",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/BarChart"),
  },
  {
    path: "/exceldownload",
    name: "exceldownload",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ExcelDownload"),
  },
  {
    path: "/exceldownload_dept",
    name: "exceldownload_dept",
    beforeEnter: onlyAuthUser,
    component: () => import("./components/ExcelDownload_Dept"),
  },
  {
    path: "/sample",
    name: "sample",
    component: () => import("./components/Sample"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
